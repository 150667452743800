@import url(https://fonts.googleapis.com/css2?family=Rubik&display=swap);
.Header_logo__3_SJs {
    width: 200px;
    -webkit-filter: invert(23%) sepia(15%) saturate(320%) hue-rotate(184deg) brightness(99%) contrast(88%);
            filter: invert(23%) sepia(15%) saturate(320%) hue-rotate(184deg) brightness(99%) contrast(88%);
}

.Header_nav__3yRlw {
    text-decoration: none;
    color: inherit;
}
html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100vh;
  scroll-behavior: smooth;

}

@counter-style thumbs {
  system: cyclic;
  symbols: "🍺" ;
  suffix: " ";
}

ul {
  list-style: thumbs;
  font-size: 1.2rem;
}

li {
  margin: 0.5rem 0;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: #FDDB3A;
}

hr {
  border-style: none;
  border-top-style: dotted;
  border-color: #FDDB3A;
  border-width: 8px;
  width: 18%;
}

.main {
  padding-top: 5vh;
  padding-bottom: 1.5vh;
}

